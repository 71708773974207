// Import Angular Material theming functions
@use "@angular/material" as mat;

// Custom blue palette centered around #2893cc
$custom-blue: (
  50: #e6f4f9,
  100: #c2e4ef,
  200: #99d2e5,
  300: #70c1db,
  400: #53b5d4,
  500: #36a9cd,
  // Close to #2893cc but lighter
  600: #319ec7,
  700: #2893cc,
  // Exactly #2893cc
  800: #2084b8,
  900: #166f9e,
  A100: #b3e5ff,
  A200: #81d4fa,
  A400: #29b6f6,
  A700: #039be5,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #000,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000,
  ),
);

// Define a Material palette from your custom color
$custom-primary: mat.m2-define-palette(
  $custom-blue,
  700
); // Primary color is #2893cc
$custom-accent: mat.m2-define-palette(
  $custom-blue,
  A200
); // A brighter shade for the accent

// Define the warn palette, typically red
$custom-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create a light theme using defined palettes
$custom-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $custom-primary,
      accent: $custom-accent,
      warn: $custom-warn,
    ),
  )
);

// Include all component themes
@include mat.all-component-themes($custom-theme);

// Optional: Define global styles
html,
body {
  height: 100%;
  margin: 0;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  background: #fff;
}
